import React from 'react'
import { memo } from 'react'

const IconSizePDF = memo(({ className }) => (
  <svg
    role="img"
    aria-label={'Icon Size Guide PDF'}
    className={'icon ' + className}
    version="1.1"
    viewBox="0 0 612 792"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <path
      d="M465.4,732.1c-4-14.4-14.6-22.1-29.9-22.8c-1.7-0.1-4.4-1.2-4.8-2.5c-3.9-11.6-12.9-18.2-23-23.3
	c-12.5-6.4-25.4-12.1-38.3-17.7c-3.7-1.6-5.6-3.6-6.1-7.6c-1.3-9.4-3.4-18.8-4.1-28.3c-1.3-17.1-2.9-33.8-15.9-46.9
	c-2.4-2.4-4-5.8-5.3-9.1c-7.6-19-15.3-38-22.4-57.2c-1.6-4.3-3.4-5.8-7.9-5.7c-15.2,0.3-27.9,12-28.3,27.4c-0.2,8,0.4,16.3,2.1,24.1
	c7.1,33.3,12,66.7,14.3,100.8c2.1,30.7-8.5,57.1-21.3,85.6c-2.8-8.9-5.2-16.1-7.4-23.4c-5-16.8-9.1-34.1-15.2-50.5
	c-6.6-17.7-18.1-32.2-34.9-41.6c-15.9-8.8-31.8-8.3-47,1.9c-7.8,5.2-10,10.2-6.6,18.6c3.4,8.5,7.7,17,13.5,24
	c4.6,5.5,7.9,10.9,9.3,17.7c0.8,4.1,1.8,8.1,2.5,12.2c4,22.5,9.2,44.7,19.9,65.1c0.3,0.5,0.6,1.1,1,1.9c2-1.4,3.5-2.3,5.1-3.4
	c-15.8-27.9-17.9-59.1-25.7-88.8c10.7-1.7,17-7.1,16.8-14.7c-0.2-11.8-5.4-21.3-13.4-29.6c-11.1,7.6-16.8,9.2-24.3,6.6
	c9.4-9.6,20.4-13.2,32.9-10.8c17.5,3.4,28.9,15.5,38.2,29.7c9.1,13.9,13.3,29.8,17.6,45.6c2.9,10.8,5.5,21.7,8.8,32.4
	c2,6.7,5.4,12.8,12.6,15.9c7.5-19.3,14.9-38.1,21.8-55.9c8.1,2.8,15.6,5.5,23.2,8.2c0.2-0.5,0.3-1,0.5-1.5
	c-6.1-2.4-12.2-4.6-18.2-7.1c-3.3-1.4-4.5-3.8-4.4-7.8c0.3-35.1-0.3-70.1-8-104.6c-3.3-14.5-5.7-29.3-7.7-44.1
	c-1.4-10.4,2-19.4,11.5-24.9c3.7-2.2,7.9-4.1,13.7-1c-3.1,1.5-5.1,2.5-7.7,3.8c2.5,9,2.9,19.8,11.2,25.3c6.9,4.6,9.4,10.9,12.9,17.3
	c5.9,10.9,12.5,21.5,18.9,32.1c1.3,2.2,3.2,4.2,6.1,8.1c-9,0.7-16.3,1.2-23.6,1.7c0,0.3,0,0.5,0,0.8c7.2,0,14.4,0,21.8,0
	c0.1,0.1,0.5,0.7,0.6,1.2c0.3,2.5,0.6,4.9,0.8,7.4c2.2,23.6,5.3,47,13.7,69.3c1.7,4.7,3.8,9.2,5.9,13.7c0.4,0.8,1.8,1.2,2.7,1.8
	c0.3-1.2,1-2.4,0.8-3.5c-0.2-1.4-1.2-2.6-1.7-4c-2.8-7.5-5.5-14.9-8.2-22.4c0.5-0.3,1-0.7,1.5-1c10.9,5,21.9,9.7,32.6,15
	c12.7,6.3,25.5,12.9,27.6,29.6c2.6,0,4.6-0.1,6.7,0c15.3,0.7,23.3,5.9,27.3,20.6c4.3,15.6,16.3,22.5,29.4,29.7
	c0.9-2.2,1.6-3.8,2.3-5.5c-1.8-1-3.2-1.7-4.6-2.6C477.2,751.6,468.8,744.6,465.4,732.1z M192.9,643.7c5.7,6.1,10.5,14.6,9.6,24.7
	c-0.3,3.6-4.4,7.9-7.9,9.8c-2.6,1.4-8,0.6-10.5-1.3c-8.7-6.6-13.4-15.8-15.6-25.7C176.5,648.7,184.1,646.3,192.9,643.7z
	 M317.8,549.1c-2.3-2.5-5.2-4.8-6.7-7.7c-2.1-4.2-3.6-8.8-4.7-13.4c-0.4-1.7,1-3.8,1.6-5.7c0.9,0.2,1.7,0.5,2.6,0.7
	c2.8,8.3,5.7,16.6,8.5,25C318.7,548.4,318.2,548.7,317.8,549.1z M321.9,317.6c0.8-4.3,1.9-8.5,2.5-12.8c0.2-1.7-0.5-3.6-0.9-5.4
	c-1.1,1.6-2.4,3.2-3.2,4.9c-0.7,1.5-0.7,3.2-1,4.8c-2.3,11.5-4.6,22.9-7,35c-3-1.8-5.4-3.2-8.5-5c0.8,16.3,1.5,31.8,2.3,47.2
	c0.3,0.1,0.7,0.1,1,0.2c6.5-14,13-27.9,19.8-42.5c-3.6,0.4-6.4,0.6-10.2,1C318.4,335.4,320.1,326.5,321.9,317.6z M460.7,585.9
	c-2.7,3.6-1.6,7.1,1.8,8.1c2.9,0.9,8.4-1.1,10.1-3.6c1.2-1.8,1.1-4.5-0.3-6.1c-1.4-1.6-3.1-1.4-4.7-0.7c-1.5,0.6-2.8,1.6-4.2,2.4
	c0.2,1.7,0.6,3,0.5,4.2c0,0.6-0.8,1.2-1.8,2.5C461.6,590.1,461.2,588.4,460.7,585.9z M469.1,584c1,1.8,1.5,2.8,2.3,4.2
	c-1.1,0.8-2.1,1.5-3.2,2.3c-0.8-0.9-1.4-1.6-2.2-2.5C466.9,586.7,467.7,585.7,469.1,584z M464.4,642c3.9,1.5,7.3,1,11.2,0.9
	c-0.4-1.3-0.7-2.2-1-3.3C470.9,640.5,468,641.2,464.4,642z M440.6,532.4c-1.7,1.1-3.3,2.4-4.9,3.6c-2.6,2-2.8,3.7-1,6.3
	c0.5,0.8,1.2,1.4,1.9,2.1c2.6,0.8,4.3,0.3,5.8-1.5c1-1.3,2.4-2.4,3.6-3.6c2.2-2.2,2.4-4.1,0.8-6.2
	C445.3,531.1,443,530.8,440.6,532.4z M437.5,542.6c-0.6-0.9-1.1-1.4-1.2-1.9c-0.2-0.7-0.1-1.5-0.1-2.1c3.3-1.1,3.3-1.1,5.3,2.2
	C440.1,541.3,438.9,541.9,437.5,542.6z M383,489c1.3,2.5,3.8,3,3.4,5.4c-1.7,2.9-5.7,4-6.6,8.1c2.4-1.3,9.7-7.1,10.7-8.6
	c0.3-0.4,0.1-1.2,0.2-2.1C388.3,490.9,386.1,490.1,383,489z M477.4,524.1c-13-18.9-29.6-33.9-48.7-46.3c-3.1-2-4.1-4.4-3.4-7.9
	c0.9-4.5,1.5-9,2.3-13.4c1.4-7.6,2.8-15.1,4.3-22.6c0.8-4.4,0.6-8.3-2.9-11.7c-1.1-1-1.7-3.1-1.7-4.6c0-1.9-0.1-3.8-0.2-5.6
	c0.2-15.2,1.5-27.7,3-37.5c4-15.4,8.1-30.7,11.8-46.1c10.9-44.8,14.7-90.2,6.4-135.8c-2.7-14.8-2-28.1,2.1-42.4
	c5.6-19,5.9-38.6-2.5-57.3c-5.6-12.5-15.1-19.5-29.3-18.9c-4.7,0.2-9.4,0.6-13.6,0.9c-2.7-7.2-5.1-14.2-8.1-21
	c-4.2-9.5-12.1-14.8-22.3-15.7c-7.4-0.7-15-1.2-22.2,0.1c-6.4,1.2-9.8,0.2-13.5-5.2c-8.1-11.7-20.7-13.1-33.8-13.3
	c-11.5-0.1-18.6,5.6-23.4,15.3c-0.3,0.7-1,1.2-1.7,1.9c-2.7-2.5-5.6-4.7-7.9-7.3c-11.3-13.2-25.5-12.6-40.5-9.1
	c-7.9,1.8-13.8,6.4-18,13.4c-7.3,12.3-11.1,25.6-9.7,39.8c1.9,18.5,5.3,36.9,7.3,55.5c0.9,8.7,0.2,17.7,0.2,26.5
	c-0.6,0.2-1.2,0.4-1.7,0.6c-7.8-13.6-15.4-27.4-23.4-40.9c-8.1-13.7-15.6-27.9-25-40.7c-9.9-13.4-23.9-20.2-41.4-16.8
	c-18,3.5-22.6,11.9-16.6,28.9c5,14.4,11,28.9,13.6,43.8c4.7,27.4,12.4,53.2,29,75.9c3.2,4.3,5.8,9.1,8.8,13.6
	c12.9,19.6,25.3,39.3,34.9,60.9c11,24.5,25.7,47,44,66.7c6,6.5,11.1,11.3,10.7,21.2c-0.1,1-0.1,1.9-0.2,2.9c0,0.3,0,0.5-0.1,0.8
	c-0.1,1.1-0.2,2.2-0.4,3.4c-1.8,0.8-2.4,1.5-4.3,2.5c-2.9,1.5-4.9,2.6-5.8,6c-3.1,12-6.6,23.8-10,35.8c-1.7,6.2-3.4,12.3-5.3,18.9
	c2.6,2.5,5,4.9,7.5,7.2c2.7,2.5,3.6,5.3,2.6,8.9c-1.3,4.5-2.2,9.1-3.2,13.7c-4.1,18.7-8.2,37.3-12.4,56c-2.9,13-6.1,25.9-9.1,38.9
	c1.6,0.9,3.3,1.9,4.9,2.8c2.8-12.5,5.5-25.1,8.3-37.6c4.7-20.8,9.4-41.5,14.1-62.3c0.6-2.8,1.2-5.5,1.7-8.3c0.3-1.4,0.6-2.8,2.8-3.4
	c1.1,0.6,2.7,1.3,4.1,2.2c5.3,3.3,10.4,7,15.9,10c8.5,4.6,17.3,8.9,26.1,12.9c7.3,3.3,14.8,6,22.2,8.8c17.2,6.2,35.2,10.2,52.2,17
	c11.5,4.6,23.2,8.7,33.7,15.3c4.2,2.7,8.8,4.9,12.8,7.9c7.7,5.8,7.5,6,6.4,15.7c-1.9,16.2-3.8,32.4-5.4,48.6
	c-2.5,25.3-4.8,50.5-7.3,75.8c-0.1,1.4-0.8,2.7-1.2,4c1.9,1.1,3.8,2.2,5.7,3.3c0.1-1.4,0.1-2.8,0.3-4.2c2.9-28.1,5.9-56.1,8.7-84.2
	c1.4-14,2.7-28.1,4.1-42.2c0.2-1.8,0.7-3.6,1.1-6.1c1.8,1.1,3.1,1.7,4,2.6c4.1,4.7,8.6,9.2,11.9,14.5c11.6,18.1,17.6,38.1,18.8,59.5
	c0.9,16.3,0.2,32.6-1.9,48.8c-0.7,5.6-1.5,11.1-2.2,16.4c1.3,1.8,2.5,2.4,4.6,1.2c0.2-1,0.5-2.2,0.7-3.4c0.9-5.9,1.8-11.8,2.5-17.7
	c3.5-27.4,3-54.4-5.1-81.1c-5.5-17.9-15.5-32.8-29.1-45.4c-4.9-4.5-10.4-8.6-16.1-12.2c-7.3-4.7-14.7-9.1-22.5-12.8
	c-7.6-3.7-15.6-6.6-23.5-9.5c-12.1-4.3-24.1-9.1-36.4-12.3c-22.8-6-43.9-15.6-64.4-26.9c-4.1-2.3-8-5-11.8-7.8
	c-6-4.5-11.9-9.1-17.8-13.8c-2-1.6-2.7-3.7-1.9-6.3c3.5-11.7,7-23.4,10.5-35.1c0.4-1.4,1.4-2.6,2.1-3.7c0.8,0.1,1.2,0,1.4,0.2
	c4.6,3.8,9.1,7.6,13.7,11.4c1.9,1.6,2.4,3.5,1.3,5.9c-0.7,1.4-1.1,2.9-1.6,4.4c-1.2,3.2-2.4,6.5-3.4,9.7c-0.2,0.7,0.3,1.5,0.7,3.3
	c0.9-1.9,1.5-2.8,1.8-3.8c1.3-3.7,2.6-7.4,3.9-11.1c1.1-2.9,1.5-3.1,5-3.1c2,1.4,3.8,2.6,5.7,3.8c1.7,1,2.4,2.4,2,4.3
	c-0.2,1.3-0.2,2.6-0.4,3.9c0.3,0.1,0.5,0.2,0.8,0.3c1.1-1.2,2.2-2.4,3.7-3.9c1,0.3,2.3,0.4,3.3,1c2.7,1.4,5.3,2.8,7.8,4.4
	c2.9,1.8,3.1,2.4,2,5.8c-0.6,2-1.8,3.9-1.1,6.2c2.2-0.3,2.4-2.1,3.1-3.4c0.7-1.5,1.3-3,2.1-5c4.3,1.7,9.1,1.5,11.2,6.1
	c-0.3,1.8-0.6,3.2-0.9,4.8c2.3,0.4,2.5-1.4,3.3-2.4c0.9-1.2,2.1-2.4,3.5-1.7c2.8,1.3,6.2,1.2,8.7,4.3c-0.3,1.5-0.5,3.4-1,5.1
	c-1.1,3.4-2.4,6.8-3.5,10.2c-0.5,1.5-1.3,3.1,0.2,5.4c3.8-6.2,4.4-13.1,7.6-19.5c1.3,0,2.8-0.2,4.3,0.1c1.8,0.3,3.6,1,5.4,1.6
	c3.8,1.2,4,1.5,3.6,5.7c-0.1,0.7,0.1,1.4,0.3,3.1c1.7-2.3,2.9-3.9,4.2-5.7c4.9,0.3,9.7,1.2,13.8,3.3c1.9,3.9-2.4,7.1-0.2,10.9
	c3.1-2.3,2-6.1,4.2-9.1c0.8,0,2.1-0.2,3.2,0.1c2.1,0.5,4.2,1.3,6.2,2.1c3.6,1.3,3.6,1.4,2.9,5c-0.1,0.8,0.1,1.6,0.2,3
	c2-2,3.6-3.5,5.4-5.2c3.8,1.5,7.6,2.9,11.1,4.3c0.9,4.2-0.9,7.2-2.3,10.4c-1.5,3.5-3.1,6.8-4.6,10.3c-0.5,1-0.7,2.1,1,2.7
	c0.6-0.8,1.2-1.5,1.6-2.4c2-4.4,3.9-8.9,5.8-13.3c0.9-2,1.3-4.3,3.8-5.2c4.2,1.5,8.6,2.6,12.1,5.9c0,2-0.1,4-0.2,6.7
	c1.3-1.1,2-1.5,2.6-2.2c2.3-2.6,2.9-2.8,6-1.2c2.6,1.4,5.3,2.8,7.8,4.4c2.7,1.7,3.2,3.4,1.8,6.4c-0.5,1.1-1.3,2.2-1.7,3.4
	c-0.2,0.6,0.2,1.5,0.5,3.2c2-3.1,3.4-5.3,4.7-7.2c2.7-1.4,4.5-0.1,6.4,1.2c1.8,1.3,3.5,2.6,5.3,3.9c2.7,2,2.9,3.3,0.9,6.2
	c-0.6,0.9-1.7,1.6-0.4,2.7c0.2,0,0.4,0.1,0.5,0.1c0.6-0.5,1.2-0.9,1.8-1.4c3.3-2.7,4-2.7,7.2,0c1.9,1.6,3.9,3.2,5.7,4.8
	c1.1,1,2,2.3,3.1,3.6c-1.2,1.9-2.1,3.5-3.3,4.9c-2.6,3.2-5.3,6.2-7.9,9.3c-1,1.2-2.3,2.5-1.9,4.6c2.9-0.6,3.9-2.9,5.4-4.6
	c2.7-3.1,5.1-6.3,7.8-9.4c1.7-2,3.5-2.1,5.2-0.2c2.5,2.7,4.8,5.8,7.1,8.7c0.3,0.4,0.2,1.2,0.3,1.5c-0.5,2.2-3.6,2.7-2.4,5.7
	c1.8-1.1,3.4-2.1,4.9-3.1c4.2,3.1,7.6,8.2,7.9,11.9c-1.9,2.4-5.4,2.9-7.2,6.2c4.3,1.4,6.3-4.2,10.5-2.6c2.1,4.1,5.6,7.9,5.8,12.2
	c-1.2,2.8-4.6,2.6-4.1,5.6c2.2-0.4,4.3-0.8,6.4-1.2c1.8,4.3,4.3,7.8,3.9,12.3c-2.7,2.1-5.9,2.6-8.7,3.8c-2.6,1.1-6.2,0.9-7.8,4.8
	c6.5-0.3,11.5-4.1,17.2-4.9c2.9,3.4,3.9,7.3,3.1,11.6c-1.4,0.8-2.8,1.7-4.3,2.5c1.6,2.7,4.1-0.2,6.2,2c0.9,3.7,1.6,8.1,1.6,12.2
	c-2.7,3.3-7.6,0.7-9.7,4.7c0.7,0.3,1.1,0.6,1.5,0.6c1.6-0.1,3.1-0.4,4.6-0.7c1.6-0.3,3.5-0.5,4.1,1.3c1.1,3.3,2.1,6.8,0.8,10.2
	c-3.4,1.2-3.5,1.3-5,3.1c1,0.3,1.8,0.5,2.6,0.8c0.9,0.3,1.7,0.6,2.4,0.9c1.3,4.3,1.3,8.9-0.2,13.2c-5.4,2.2-11.7-0.3-18.1,3
	c6.6,2.2,12.8-0.7,18.4,1.5c0.4,3.5,1.1,6.7-0.4,9.5c-1.9,1.7-4.9,1.2-5.9,4.1c1.9,0.2,3.6,0.4,5.2,0.6c1.2,4.3-0.3,8.1-0.4,12
	c-0.1,3.2-1.7,4.3-5,4.2c-1.5-0.1-3-0.8-4.8,0.5c2.2,2.8,6.1,0.7,8.3,3.5c0.7,4.1-0.8,8.3-1.2,12.4c-2,1-3.7,1.8-5.6,2.7
	c1.4,1,2.5,1.8,3.9,2.8c-0.2,1.5-0.3,3.1-0.5,4.6c-0.9,5.1-1.8,10.2-2.7,15.3c-0.3,1.5-0.3,3-0.4,4.9c1.8,0.1,3.1,0.2,4.5,0.3
	c0.8-3.3,1.4-6.2,2.1-9.1c5.5-25.2,8.8-50.7,9.2-76.4c0.3-20.2-2-40.1-7.6-59.5C493.3,552.5,486.7,537.7,477.4,524.1z M403.3,83.4
	c11.7-6.7,27.2-4.3,34.8,5.3c-7-0.6-14-1.2-21.1-1.8c-0.1,0.8-0.1,1.5-0.2,2.3c1.8,0,3.6,0.1,5.4,0c14.9-0.6,21.2,4.2,24.3,18.6
	c3.8,17.6,0.5,34.6-4.5,51.5c-1.1,3.6-2.3,7.1-3.5,10.8c-2.1-1.1-3.8-2.2-5.6-2.9c-8.1-3.2-13.7-0.6-16.6,7.5
	c-1.9,5.4-4.1,10.8-6.4,17c4.6,2.1,9,4.1,13.3,6.1c-10.4,4.6-20.5-1.7-20.4-13.3c0.1-11.6,0.9-23.2,1.2-34.8
	c0.5-14.2,1.1-28.4,1.1-42.6C405.1,99,403.9,91.2,403.3,83.4z M438.5,180.2c-1.9,4.7-4.8,9.1-7.8,13.3c-0.9,1.2-3.8,1.9-5.4,1.6
	c-4.3-0.9-8.5-2.5-13.1-3.9c2.4-6.4,4.4-11.8,6.5-17.2c2.1-5.6,6.3-7.4,12.5-5.4C437,170.5,440.5,175.2,438.5,180.2z M383.3,47.7
	c5.4,3.2,9.5,10.6,11.6,16.9c10.2,31.9,10.4,64-0.3,95.9c-2.4,7.2-5.3,14.4-7.6,20.7c-4.3-2.9-8-6.4-12.3-8
	c-5.2-1.8-10.1,0.2-13.2,5.4c-3.7,6.2-7.6,12.2-11.9,19c4.3,2,9.1,4.3,14.8,6.9c-7.2,1.9-13,0.6-17.4-4.6
	c-4.1-4.8-3.6-10.1-1.2-15.7c8.8-20.3,15.2-41.8,16.2-63.5c0.8-16.4-4.2-33.1-7.6-49.5c-1.8-8.4-5.7-16.3-8.9-24.9
	C358.5,41.9,371.7,40.8,383.3,47.7z M381.7,190.2c-9.2,13.7-13.3,15.6-28.8,6.6c3.5-5.9,6.8-11.4,10.1-16.9
	c3.1-5.1,9.6-6.7,15.2-3.5C383.2,179.2,385.1,185.1,381.7,190.2z M285.5,41.5c6.9-15.8,22-20.5,39.1-12.3
	c-5.6,0.9-11.1,1.7-16.6,2.6c0.1,0.7,0.2,1.4,0.4,2.1c2.8-0.6,5.6-1.2,8.4-1.7c8.7-1.4,15.6,0.6,20.8,8.6
	c11.5,17.6,16.5,37.3,20.1,57.6c0.9,5.3,1.3,10.6,2,16.4c-7.7,0-14.5,0-21.2,0c0,0.5,0,1.1,0,1.6c5.3,0,10.5-0.2,15.8,0.1
	c3.8,0.2,5.9,1.6,5,6.3c-3.2,18.1-7,36-13.5,53.6c-11.4-10.8-22.4-8.4-28.2,5.7c-1.4,3.4-2.7,7-4.6,10.2c-2.7,4.6-3.6,8.4,3.6,10.8
	c-9.9,0-16.9-7.2-15.9-15.6c1-8,2.5-16,3.8-23.9c0.1-0.6,0.1-1.4,0.5-1.8c11.3-10,7.5-23.8,8.6-36.1c0.7-8.6-1.3-17.3-1.3-26
	c0-14.1-6.3-25.5-14.2-36.3c-3.8-5.2-8.1-10-11.7-15.3C285.2,46.4,284.7,43.3,285.5,41.5z M342.4,184.7c-1.5,3.6-3.5,7-5.7,10.2
	c-6.9,10.2-13.8,11.1-24,3c2.8-6.6,5.3-12.9,8.2-19.1c2.6-5.4,7.5-7.7,13.2-7.3C341.4,172,345.2,178.1,342.4,184.7z M308.8,152
	c-8.9-0.5-16.2-1-24.3-1.4c0-7.6-0.7-15.6,0.2-23.3c0.8-6.7,8.4-10.7,15.8-9.6c6.5,1,10.2,5.4,9.9,12.6
	C310.1,137.6,309.3,144.9,308.8,152z M118.9,110.8c-3.8-10-7.4-20-11.1-30c6.7-0.6,13-1.1,21-1.8c1.7,8,4.1,16.5,5.3,25.1
	c0.7,5.2-7,10.8-12.2,9.4C120.7,113.2,119.3,112,118.9,110.8z M296.8,425.5c-4.5-1.5-5.6-2.8-4.9-5.4c0.9-3.1,3.2-3.8,7.7-2.4
	C301.3,422,300.9,423.2,296.8,425.5z M367.3,447c-2.5,1.7-4.5,0.7-6.4,0.2c-11.3-3.2-22.5-6.5-33.7-9.9c-6.6-2-13-4.3-19.5-6.6
	c-3.3-1.2-4.1-2.8-2.9-6.1c0.5-1.4,1.4-2.6,2.3-4.2c4.4,0.8,8.5,1.6,12.6,2.4c0.5,2.2,0.9,4,1.3,5.8c3.4,0.3,1.2-4,3.9-4
	c4.4-0.2,7.2,0.5,11.7,3.2c-0.2,2.6-0.4,5.4-0.7,8.8c4-1.8,1.9-6.3,5.4-8.4c3.8-0.2,8.2,0.6,12.9,1.8c0.1,2.3,0.3,4.4,0.5,7.3
	c1.1-1.1,1.8-1.6,2.1-2.2c0.6-1.2,1-2.5,1.4-3.8c3.9-0.8,7.1,0.4,10.7,0.9C371.6,437.8,368.6,442.5,367.3,447z M425,441.1
	c-1.6,9.9-3.3,19.7-5.1,30.2c-1.7-0.4-2.9-0.5-3.9-1c-8.5-4-16.9-8.3-25.4-12.2c-4.7-2.1-9.6-3.8-14.5-5.6c-5.5-2-6.4-3.5-5.1-9.2
	c0.6-2.4,1.1-4.9,1.9-7.3c0.8-2.5,1.5-3.1,4.1-2.8c3.3,0.3,6.5,0.9,9.9,1.4c0.5,2.1,0.9,4,1.4,6.5c1.4-2,2.3-3.4,3.5-5
	c4.1-0.8,8.2,0.4,12.4,0.9c2.2,4.8-1.2,9.5,0.1,14.8c3.1-2,2.3-4.8,2.8-7c0.5-2.3,0.8-4.6,1.2-6.6c4.4-2,6.6-2,10.3-0.4
	c0.4,1.3,0.9,2.8,1.6,4.8c1.1-1.6,2-3,3.4-5l0.8-0.1C424.6,439.3,425.1,440.3,425,441.1z M421.5,421c-0.1,1.4-0.2,2.8-0.3,4.2
	c-0.1,1.8-0.6,3.6-0.8,5c-2.4,1.8-4.7,1.4-6.8,1.4c-13.4-0.1-26.6-1.4-39.7-3.9c-19.6-3.7-39.1-7.4-58.6-11.4
	c-6.3-1.3-12.3-3.6-18.5-5.3c-1.8-0.5-3.6-0.9-5.5-1.4c-1.8,3.6-3.4,6.9-5.2,10.7c-1.9-0.7-3.4-1.1-4.7-1.8
	c-10.3-5.6-20.2-12-29.6-19.1c-0.3-0.3-0.6-0.5-0.9-0.8l0-2.5c-0.2-12.2,0.8-24.7-0.4-36.3c10.7,5.1,21.9,8.6,35.1,7.8
	c-1.7-1.7-3.7-2.7-5.8-3c-13-2.1-26.4-4.5-35.8-14.3c-16.4-17.1-30.5-36.1-41.5-57.2c-12.2-23.3-22.3-47.7-37.9-69.1
	c-6.4-8.9-12-18.4-17.9-27.7c-2.8-4.4-5.4-9.1-7.8-13.2c6-0.9,11.6-1.7,17.1-2.5c-0.2-0.9-0.3-1.9-0.5-2.8c-2.2,0.5-4.4,1.1-6.6,1.6
	c-6.8,1.4-13.5-0.7-14.8-5.7c-5-18.7-9.6-37.5-14.3-56.4c15.8-2.9,18.9-7.2,16.3-21.2c-0.8-4.1-1.8-8.1-3.1-12c-3-9-3-9-12.4-7
	c-1.2,0.3-2.5,0.8-3.7,0.6c-3.6-0.8-7.2-1.9-10.8-2.9c2.4-2.7,4.4-6.3,7.4-8c13.6-7.6,29.7-4.4,41.2,9.4
	c8.1,9.8,14.6,21.1,21.2,32.1c18.4,30.9,36.5,62,54.5,92.8c18-4.1,34.4-1.1,50.8,1.8c-13.8-6.2-28.2-8.1-42.9-6.1
	c-4.3,0.6-6.1-0.5-8.1-4.2c-3.2-6-7-11.8-11-17.3c-3-4.1-4.6-7.7-2.4-12.9c1.5-3.5,1.9-7.8,1.6-11.6c-1.2-15.1-2.5-30.1-4.4-45.1
	c-2-16-6.7-32-2.9-48.1c6.2-26.6,14.7-31.9,36.2-33.7c3.4-0.3,7,0.9,10.5,1.4c-8.6,3.2-18.2,2.6-23.8,11.3c1.3-0.3,2.4-1.1,3.4-1.8
	c10.9-8,24.5-7.7,33.5,2.2c10.6,11.6,20.5,23.8,30,36.3c10.5,13.8,8.4,30.4,9.7,47.1c-2.4-1.5-3.7-2.4-5.1-3.1
	c-9.5-4.8-21.3,0-22.8,10.4c-1.2,8.4-0.2,17.1-0.2,24.9c9,0.5,16.5,1,24,1.5c-11,11.8-28.5,9.2-36.3-4.3
	c-11.4-19.7-13.9-40.9-8.6-63.1c0.5-2,1.1-4,1.6-6c-0.9-0.2-1.8-0.5-2.7-0.7c-1.8,7.1-3.6,14.2-5.9,23c-9-6.3-17.7-12.4-26.3-18.4
	c0.3,2.3,1.2,4.1,2.7,5.2c6.3,4.6,12.5,9.6,19.4,13.2c4,2.1,5.6,4.2,5.9,8.7c0.9,14.6,3.9,28.8,12.2,41.3
	c5.9,8.8,14.3,12.2,24.6,11.5c2.6-0.2,5.2-0.7,8.5-1.1c-1.2,8.1-2.5,15.8-3.6,23.6c-1.1,7.8,2.4,13.2,8.8,17.1
	c11.3,6.8,23.3,4.6,31.5-5.7c0.8-1,1.4-2.2,2.4-3.9c4.8,11.4,12.9,15.8,22.8,13.7c9.3-2,16.5-7.5,20.5-15.8
	c5.6-11.5,10-23.6,15-35.4c0.6-1.3,1.1-2.7,1.6-4c0.4,11-0.3,21.8-0.6,32.6c-0.2,6.5,2.6,11.7,8.5,14.7c9,4.6,20.1,2,26.1-6.2
	c2.1-2.9,3.9-5.9,6.5-9.7c8,41.5,7.2,81.7-0.6,121.8c-2.9,14.8-6.6,29.4-10.3,44c-1.1,3.5-2.8,9.2-4.4,17.3c0,0.1-0.1,0.3-0.1,0.4
	c-0.3,1.1-0.5,2.2-0.5,3.2C422.9,383,421.1,399.8,421.5,421z M312.4,470.7c-0.3,0.7-0.7,1.4-0.8,2.2c-0.5,2.6-1.4,5.5,1.3,7.3
	c2.6,1.8,5.1,0.5,7.2-1.3c0.2-0.2,0.3-0.5,0.7-1.2c-0.2-1.5-0.5-3.3-0.8-5c0.6-1.2,1.2-2.4,1.7-3.5
	C317.1,465.5,314.5,466,312.4,470.7z M315.4,478.8c-2.3-3.1-2.3-3.1,0.7-5.3C318.4,476.2,318.4,476.2,315.4,478.8z M255.2,438
	c-1.6-0.6-3.3-1.3-5.1-2c-1.2,2.3-3.2,3.6-2.9,6.2c1.5,0.9,2.9,1.7,4.6,2.6c-0.6,2.7-1.4,4.4-3.8,4.6c-1.1-1.4-2-2.7-3.1-4.1
	c-0.5,0.7-0.9,1-0.8,1.2c0.3,2.1,1.7,3.6,3.9,4c2,0.4,3.8-0.1,5.1-1.8c1.3-1.7,1.3-3.5,0.2-5.2c-0.8-1.2-1.7-2.5-2.8-4
	c2-0.3,3.3-0.5,4.7-0.7C255.1,438.6,255.1,438.3,255.2,438z M464,647.7c-3,1.9-3.1,4.7-0.4,6.8c3.2,2.6,6.9,2.4,11.9-0.9
	c0.1-1.3,0.3-2.8,0.5-4.1C471.5,646.3,467.3,645.7,464,647.7z M473.6,652c-2.6,2.8-5.6,2.3-9.3,1c0.2-1.3,0-2.5,0.5-2.9
	c1-0.8,2.4-1.4,3.6-1.7C471.8,647.8,472.5,648.3,473.6,652z"
    />
  </svg>
))

export default IconSizePDF
