import * as React from 'react'
import { useState } from 'react'
import { IFrame } from '../homepage/YouTube'
import Section from '../cms/Section'
import InputField from '../form/InputField'
import FormWrapper from '../form/FormWrapper'
import useFormState from '../form/hooks/useFormState'
import isEmail from '../form/validators/isEmail'
import IconSizePDF from '../../icons/IconSizePDF'
const SizeGuideComponent = ({ sizeGuide, sizeGuideDetails, product }) => {
  const [emailPDF, setEmailPDF] = useState(false)
  const requestEmailPDF = () => setEmailPDF(true)
  const [form, setForm, updateForm] = useFormState({
    email: '',
    sku: product.sku,
    product: product.name,
    url: product.url,
    image: product.image.url,
    description: product.description,
    pdf: sizeGuide,
  })

  return (
    <div className="my-20" ref={sizeGuideDetails}>
      <hr className="-z-10 " />
      <div className=" mx-auto max-w-max uppercase -translate-y-6 border border-gray-300 bg-white px-3 py-2 ">
        {product.productSizeGuide} Size Guide
      </div>

      <div className="size-guide-expand flex flex-col lg:flex-row">
        <div className="lg:w-1/2 landscape:pr-4 ">
          {product?.sizeGuide?.sizeGuideSections?.map((section, i) => {
            return <Section key={i} section={section} />
          })}
        </div>
        <div className=" px-5 md:px-0 py-5 lg:py-0 rounded-xl w-full lg:w-1/2 md:max-w-[600px] md:mx-auto flex flex-col xs:flex-row lg:flex-col justify-center items-center md:sticky top-0 h-full bg-gray-200 lg:bg-none">
          <IFrame
            className={'hidden lg:block aspect-[85/120] w-full'}
            title={'Size Guide'}
            src={sizeGuide}
          />
          <div className="w-10/12 max-w-48 mx-auto text-gray-500 lg:hidden bg-gray-50 shadow-md rounded-lg p-3">
            <IconSizePDF className="w-full h-full" />
          </div>
          {emailPDF ? (
            <p>
              Thank you for your request!
              <br />
              Our Size Guide PDF has been sent.
            </p>
          ) : (
            <FormWrapper
              form={form}
              name={'Size Guide Email'}
              action={requestEmailPDF}
              method={'POST'}
              formClassName={'w-full max-w-72 '}
              className=" lg:hidden w-10/12 max-w-96 mx-auto flex flex-col gap-4 justify-center items-center"
            >
              <InputField
                errorMessage=""
                name={'email'}
                label={'E-Mail'}
                form={form}
                validator={isEmail}
                onChange={updateForm}
                className={'w-full'}
                required={true}
              />
              <button
                // TODO: greg, be sure to fix the requestEmailPDF to send email to client.
                className="bg-red-600 text-white font-bold px-4 py-2 max-w-max lg:hidden"
              >
                EMAIL PDF
              </button>
            </FormWrapper>
          )}
        </div>
      </div>
    </div>
  )
}
export default SizeGuideComponent
